import { Box, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";

import carTypeIcon from "../../assets/icons/cardIcons/car_type_grey.svg";
import priceGroupIcon from "../../assets/icons/cardIcons/price_group_grey.svg";
import seatsIcon from "../../assets/icons/cardIcons/seats_grey.svg";
import transmissionIcon from "../../assets/icons/cardIcons/transmission_grey.svg";
import fuelIcon from "../../assets/icons/cardIcons/fuel_grey.svg";
import { Text, TextBold } from "../text/Text";
import { Context } from "../../context/Store";
import FlexBoxRow from "../flexBox/FlexBoxRow";
import {
  calculatePrice,
  getPriceBase,
  isHourPrice,
  tax,
} from "../../utils/price";
import StyledButton from "../styledButton/StyledButton";

import Picture from "../picture/Picture";
import redInfoIcon from "../../assets/icons/info_red.svg";
import greyInfoIcon from "../../assets/icons/info_grey.svg";
import FlexBoxColumn from "../flexBox/FlexBoxColumn";
import StyledPopover from "../styledPopover/StyledPopover";
import { formatPrice, formatPriceSEK } from "../../utils/formating";

import Linkify from "react-linkify";
import PricePopUp from "../pricePopUp/PricePopUp";

const ExtraInfo = (info, campaign = null) => {
  const infoList = Object.keys(info).map((key) => {
    return { header: key, info: info[key] };
  });

  let campaignText;
  if (campaign && campaign.status) {
    const campaignEnd = new Date(campaign.end._seconds * 1000);
    const campaignStart = new Date(campaign.start._seconds * 1000);
    campaignText = `Kampanjpris gäller från ${campaignStart.toLocaleDateString()} till ${campaignEnd.toLocaleDateString()}`;
  }

  infoList.sort((a, b) => a.header.localeCompare(b.header));
  return (
    <FlexBoxColumn
      className="noscrollbar"
      alignItems="start"
      lineHeight="1.4"
      flexWrap="nowrap"
      overflow="scroll"
      maxHeight="85vh"
      mt="-2px"
      width={["100%", "100%", "900px"]}
    >
      {infoList.map((text) => {
        return (
          <FlexBoxColumn
            alignItems="start"
            pt={["15px", "30px"]}
            px="15px"
            borderTop="1.5px solid #E0E0E0"
            flexGrow="1"
            mb="30px"
          >
            <TextBold>{text.header}</TextBold>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="_blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {text.info.map((paragraph) => {
                return <Text className="enterWrap">{paragraph}</Text>;
              })}
            </Linkify>
          </FlexBoxColumn>
        );
      })}
      {campaign && campaign.status ? (
        <>
          <TextBold fontSize={20} color="#EC2B34">
            Kampanj
          </TextBold>
          <Box flexDirection={"columns"}>
            {campaignText && <Text className="enterWrap">{campaignText}</Text>}

            {campaign.day && campaign.day.unit !== null && (
              <Text className="enterWrap">
                {campaign.day.price} kr/dag inklusive {campaign.day.includedKm}{" "}
                km. Extra km tillkommer med {campaign.kmPrice} kr/km.
                <br />
                Erbjudandet går ej att kombinera med andra erbjudanden eller
                rabatter.
              </Text>
            )}
            {campaign.week && campaign.week.unit !== null && (
              <Text className="enterWrap">
                {campaign.week[1]} kr/vecka inklusive {campaign.includedKm * 7}{" "}
                km. Extra km tillkommer med {campaign.kmPrice} kr/km.
                <br />
                Erbjudandet går ej att kombinera med andra erbjudanden eller
                rabatter.
              </Text>
            )}
            {campaign.month && campaign.month.unit && (
              <Text className="enterWrap">
                {campaign.month.price} kr/månad inklusive{" "}
                {campaign.INKLUSIVE_KM_MONTH} km. Extra km tillkommer med{" "}
                {campaign.kmPrice} kr/km.
                <br />
                Erbjudandet går ej att kombinera med andra erbjudanden eller
                rabatter.
              </Text>
            )}
          </Box>
        </>
      ) : (
        <></>
      )}
    </FlexBoxColumn>
  );
};

const InfoText = ({ icon, text }) => {
  return (
    <FlexBoxRow mb={1} justifyContent={["center", "center", "left"]}>
      <img
        src={icon}
        alt=""
        style={{ width: "20px", height: "19px", marginRight: "10px" }}
      />
      <Text mr={[0, 0, 1]}>{text}</Text>
    </FlexBoxRow>
  );
};

const CarCardInfo = ({
  buttonText,
  car,
  timeUnspecified,
  bookingTime,
  onSelect,
  setPriceInfo,
  opacity,
  loading,
  hideButton,
  height,
  hidePrice,
  buttonFontSize,
}) => {
  const {
    modell,
    priceGroup,
    seats,
    fuel,
    license,
    info,
    moreInfo,
    agelimit,
    campaign,
  } = car;
  const { priceLists, setPopUp } = useContext(Context);
  const [price, setPrice] = useState(0);
  const [diffPrice, setDiffPrice] = useState(0);
  const [isCampaign, setIsCampaign] = useState(false);
  const [perTimePeriod, setPerTimePeriod] = useState("");
  const [priceData, setPriceData] = useState(null);
  const transmission = useMemo(() => {
    let transmissions = [];
    for (const carItem of car.availableCars) {
      if (carItem.transmission === "Automat" && !transmissions.includes("A")) {
        transmissions.push("A");
      }
      if (carItem.transmission === "Manuell" && !transmissions.includes("M")) {
        transmissions.push("M");
      }
    }
    transmissions.sort();
    return transmissions.join(" eller ");
  }, [car.availableCars]);
  const towbar = useMemo(() => {
    for (const carItem of car.availableCars) {
      if (carItem.towbar === true) {
        return true;
      }
    }
    return false;
  }, [car]);

  const hasInfo =
    moreInfo &&
    Object.keys(moreInfo).length !== 0 &&
    moreInfo.constructor === Object;
  const showInfo = () => {
    setPopUp(ExtraInfo(moreInfo, priceData.campaign));
  };

  useEffect(() => {
    if (!priceLists || hidePrice) {
      return;
    }

    let time = bookingTime;
    if (!bookingTime) {
      const startDate = new Date();
      const endDate = new Date(startDate);
      endDate.setHours(startDate.getHours() + 1);
      const distance = 0;
      time = { startDate, endDate, distance };
    }
    const { startDate, endDate, distance } = time;

    const totalTime = endDate - startDate;
    const hourToMs = (hour) => {
      return hour * 60 * 60 * 1000;
    };

    const days = Math.ceil(totalTime / hourToMs(24));
    const hours = Math.ceil(totalTime / hourToMs(1));

    calculatePrice(
      startDate,
      endDate,
      distance,
      priceLists,
      car.debitedCargroup,
      false,
    )
      .then((price) => {
        setDiffPrice(price.total.customer);
      })
      .catch();

    calculatePrice(
      startDate,
      endDate,
      distance,
      priceLists,
      car.debitedCargroup,
      true,
    )
      .then((price) => {
        if (price.campaign.status) {
          setIsCampaign(true);
        }
        if (!price.base) {
          setPrice("Error");
          return;
        }
        if (price.base.type === "T04") {
          setPerTimePeriod("för 4 timmar");
        } else {
          setPerTimePeriod("per dag");
        }
        const pricePerDay = Math.ceil(price.total.customer / days);

        if (!price) {
          setPrice("Error");
          return;
        }
        if (!price.total) {
          setPrice("Error");
          return;
        }
        if (!price.total.customer) {
          setPrice("Error");
          return;
        }
        if (price.total.customer === 0) {
          setPrice("Error");
          return;
        }

        setPriceData({ ...price, days, hours });
        setPrice(price.total.customer);

        if (setPriceInfo) {
          setPriceInfo({ ...price, days, hours, pricePerDay });
        }
      })
      .catch();
  }, [setPriceInfo, priceLists, bookingTime, priceGroup]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      bgcolor={"#FFF"}
      border={"1.25px solid #F2F2F2"}
      borderRadius={5}
      display="flex"
      flexDirection={["column", "column", "row"]}
      justifyContent={["flex-start", "flex-start", "space-around"]}
      alignItems="center"
      height={[height ?? "auto", height ?? "auto", 210]}
      style={{ opacity: opacity ?? 1 }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        overflow="hidden"
        height={["170px", "170px", "100%"]}
        width={"300px"}
      >
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          height={"100%"}
          position={"relative"}
        >
          {isCampaign ? (
            <Box
              position={"absolute"}
              width={"100%"}
              height={"40px"}
              bgcolor={"red"}
              marginBottom={"-40px"}
              zIndex={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              left={["55px", "0px", "55px"]}
              top={["15px", "-20px", "20px"]}
              style={{
                transform: "rotate(30deg)",
              }}
            >
              <Typography
                fontSize={35}
                style={{ color: "white", fontSize: "25px" }}
              >
                Kampanj
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          <Picture
            name={car.name}
            carclass={car.carClass}
            width={["100%", "100%", "250px"]}
            minWidth={["100%", "100%", "250px"]}
            border={["5px 5px 0px 0px", "5px 5px 0px 0px", "5px 0px 0px 5px"]}
            display="flex"
            justifyContent="center"
            alignItems="center"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        ml={["0px", "0px", "20px"]}
        width={["100%", "100%", "400px"]}
      >
        <TextBold
          textAlign={["center", "center", "left"]}
          fontSize={["26px", "26px", "30px"]}
          mb={["25px", "25px", "20px"]}
          mt={["25px", "25px", "0px"]}
        >
          {modell}{" "}
          <Text
            component="span"
            fontSize={["18px", "18px", "20px"]}
            style={{ whiteSpace: "pre" }}
          >
            eller liknande
          </Text>
        </TextBold>

        <FlexBoxRow
          flexWrap="wrap"
          justifyContent={["center", "center", "flex-start"]}
          fontSize={[14, 14, 16]}
          width={["80%", "80%", "auto"]}
          mx={["auto", "auto", "0px"]}
          textAlign={["center", "center", "left"]}
          mb={["20px", "20px", "0px"]}
        >
          {license && (
            <Box mx={["10px", "10px", "0px"]} width={["auto", "auto", "33%"]}>
              <InfoText icon={priceGroupIcon} text={license} />
            </Box>
          )}
          {priceGroup && (
            <Box mx={["10px", "10px", "0px"]} width={["auto", "auto", "33%"]}>
              <InfoText icon={carTypeIcon} text={priceGroup} />
            </Box>
          )}

          {seats && (
            <Box mx={["10px", "10px", "0px"]} width={["auto", "auto", "33%"]}>
              <InfoText icon={seatsIcon} text={seats} />
            </Box>
          )}
          {transmission && (
            <Box mx={["10px", "10px", "0px"]} width={["auto", "auto", "33%"]}>
              <InfoText icon={transmissionIcon} text={transmission} />
            </Box>
          )}
          {fuel && (
            <Box mx={["10px", "10px", "0px"]} width={["auto", "auto", "33%"]}>
              <InfoText icon={fuelIcon} text={fuel} />
            </Box>
          )}
          {towbar && (
            <Box mx={["10px", "10px", "0px"]} width={["auto", "auto", "33%"]}>
              <InfoText icon={carTypeIcon} text={"Dragkrok"} />
            </Box>
          )}
        </FlexBoxRow>
        {info !== null &&
          info.map((information) => (
            <Text
              width="100%"
              textAlign={["center", "center", "left"]}
              key={information}
            >
              {information}
            </Text>
          ))}
        {agelimit !== "" && agelimit !== undefined && (
          <Text width="100%" textAlign={["center", "center", "left"]}>
            Minimiålder: {agelimit} år
          </Text>
        )}
      </Box>
      <FlexBoxColumn
        mr={["0px", "0px", "20px"]}
        width={["80%", "80%", "200px"]}
        mb={["20px", "20px", 0]}
      >
        {timeUnspecified && (
          <Text fontSize={16} textAlign="center" color="#767676">
            från
          </Text>
        )}
        {!hidePrice && (
          <FlexBoxRow my={timeUnspecified ? "0xp" : "16px"}>
            {isCampaign && diffPrice > price ? (
              <Box flexDirection={"column"}>
                <Box flexDirection={"column"}>
                  <TextBold fontSize={30} color="#808080" mr="5px">
                    {formatPriceSEK(diffPrice)}
                  </TextBold>
                  <Box
                    bgcolor={"#000000"}
                    width="98%"
                    height={"4px"}
                    marginTop={"-4px"}
                    position={"relative"}
                    left={"1px"}
                    bottom={"16px"}
                    style={{
                      transform: "rotate(10deg)",
                    }}
                  />
                </Box>

                <TextBold fontSize={30} color="#EC2B34" mr="5px">
                  {formatPriceSEK(price)}
                </TextBold>
              </Box>
            ) : (
              <TextBold fontSize={30} color="#000000" mr="5px">
                {formatPriceSEK(price)}
              </TextBold>
            )}

            <StyledPopover popover={PricePopUp(priceData)}>
              <img src={greyInfoIcon} alt="" />
            </StyledPopover>
          </FlexBoxRow>
        )}
        {timeUnspecified && (
          <Text fontSize={16} textAlign="center" color="#767676" mb="20px">
            {perTimePeriod}
          </Text>
        )}
        {!hideButton && (
          <StyledButton
            width="100%"
            height="44px"
            loading={loading}
            color={buttonText && !hidePrice ? "secondary" : "primary"}
            onClick={() => {
              if (price === "Error") {
                return;
              }
              onSelect(car);
            }}
          >
            <TextBold fontSize={buttonFontSize || "18px"}>
              {buttonText ?? "Välj"}{" "}
            </TextBold>
          </StyledButton>
        )}
        {hasInfo && (
          <FlexBoxRow
            onClick={showInfo}
            mt="10px"
            style={{ cursor: "pointer" }}
          >
            <img src={redInfoIcon} alt="" />
            <Text style={{ textDecoration: "underline" }} ml="5px">
              Mer info
            </Text>
          </FlexBoxRow>
        )}
      </FlexBoxColumn>
    </Box>
  );
};

export default CarCardInfo;
